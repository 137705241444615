/* Primary color */
.home-message:hover {background-color:#2d5c88; background-color: rgba(45, 92, 136, 0.6);}
nav.woocommerce-pagination ul li a:hover, .wp-pagenavi a:hover, .panel-heading .accordion-toggle, .variations .kad_radio_variations label:hover, .variations .kad_radio_variations label.selectedValue {border-color: #2d5c88;}
a, #nav-main ul.sf-menu ul li a:hover, .product_price ins .amount, .price ins .amount, .color_primary, .primary-color, #logo a.brand, #nav-main ul.sf-menu a:hover,
.woocommerce-message:before, .woocommerce-info:before, #nav-second ul.sf-menu a:hover, .footerclass a:hover, .posttags a:hover, .subhead a:hover, .nav-trigger-case:hover .kad-menu-name, 
  .nav-trigger-case:hover .kad-navbtn, #kadbreadcrumbs a:hover, #wp-calendar a, .star-rating {color: #2d5c88;}
.widget_price_filter .ui-slider .ui-slider-handle, .product_item .kad_add_to_cart:hover, .product_item:hover a.button:hover, .product_item:hover .kad_add_to_cart:hover, .kad-btn-primary, html .woocommerce-page .widget_layered_nav ul.yith-wcan-label li a:hover, html .woocommerce-page .widget_layered_nav ul.yith-wcan-label li.chosen a,
.product-category.grid_item a:hover h5, .woocommerce-message .button, .widget_layered_nav_filters ul li a, .widget_layered_nav ul li.chosen a, .wpcf7 input.wpcf7-submit, .yith-wcan .yith-wcan-reset-navigation,
#containerfooter .menu li a:hover, .bg_primary, .portfolionav a:hover, .home-iconmenu a:hover, p.demo_store, .topclass, #commentform .form-submit #submit, .kad-hover-bg-primary:hover, .widget_shopping_cart_content .checkout,
.login .form-row .button, .kad-post-navigation .kad-previous-link a:hover, .cart_totals .checkout-button, .wpcf7 input.wpcf7-back,
.kad-post-navigation .kad-next-link a:hover, .variations .kad_radio_variations label.selectedValue, #payment #place_order, .shop_table .actions input[type=submit].checkout-button, input[type="submit"].button, .order-actions .button {background: #2d5c88;}

/* 20% lighter than Primary */
a:hover {color: #6c8dab;}
.kad-btn-primary:hover, .login .form-row .button:hover, #payment #place_order:hover, .yith-wcan .yith-wcan-reset-navigation:hover, .widget_shopping_cart_content .checkout:hover,
.woocommerce-message .button:hover, #commentform .form-submit #submit:hover, .wpcf7 input.wpcf7-submit:hover, .wpcf7 input.wpcf7-back:hover, .widget_layered_nav_filters ul li a:hover, .cart_totals .checkout-button:hover,
.widget_layered_nav ul li.chosen a:hover, .shop_table .actions input[type=submit].checkout-button:hover, .order-actions .button:hover, input[type="submit"].button:hover {background: #6c8dab;}

.product_item.hidetheaction .kad_add_to_cart {background: transparent; color: transparent;}
.product_item:hover .kad_add_to_cart, .product_item:hover a.button {background: #6c8dab; color: #fff;}

.bg-lightgray {
  background: #f2f2f2;
}

.sf-menu a {letter-spacing: normal;}
#nav-second ul.sf-menu a {letter-spacing: normal;}

.color_gray, #kadbreadcrumbs a, .subhead, .subhead a, .posttags, .posttags a, .product_meta a {color: #777;}

body, .color_body, .sidebar a, .author-name a, .author-latestposts h5 a, .author-latestposts h5, 
.author-profile .author-occupation, .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus, .product_price, .product_details > .product_excerpt {color:#444;}

/* General */
a:hover {text-decoration: none;}
.hometitle {text-align: center;}

body {
  font-family: "Libre Franklin", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-transform: none;
  color: #333;
  font-family: Verdana, Geneva, sans-serif;
  line-height: 25px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato;
  line-height: 40px;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3 {
  color:#333;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
  color: #444;
}

h5 {
  font-size: 18px;
  color: #555;
}

p {
  margin-bottom: 2rem;
}

.content-narrow {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .kad-desktop-slider {
    display: none;
  }
}
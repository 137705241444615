.icon-arrow-up {
    position: relative;
    height: 100%;

    &:before {
        $width: 12px;

        content: "";
        position: absolute;
        width: $width;
        height: $width;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
        top: 55%;
        left: 50%;
    }
}
